import React from "react";
import "../css/Footer.css";

import { LogoFacebook, LogoInstagram } from "react-ionicons";

function Footer() {
  return (
    <footer className="container my-4 foter">
      <hr></hr>
      <div className="row justify-content-center border-bottom pb-3 mb-3 conte">
        <div className="col-md-6">
          <h3 className="txtBuscar">
            • Si lo pasas a buscar, encontranos aca!
          </h3>

          <iframe
            className="mapa"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13618.68474309394!2d-64.1570012!3d-31.4231843!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432a2b728a0e195%3A0x9498057d331a2132!2sPoller%C3%ADa%20Erika!5e0!3m2!1ses-419!2sar!4v1710204744295!5m2!1ses-419!2sar"
            width="100%"
            height="200"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <div className="col-md-6">
          <br />
          <div className="row justify-content-center">
            <div className="col-8">
              <h4 className="txtBuscar">
                • No te olvides dejarnos tu reseña de como te atendimos!
              </h4>
            </div>
            <div className="col-4">
              <a href="https://g.page/r/CTIhGjN9BZiUEBM/review" className="btn btn-danger btnFooter">Comentar</a>
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-8">
              <h4 className="txtBuscar">
                • Te dejamos nuestro número de telefono por cualquier cosa
              </h4>
            </div>
            <div className="col-4">
              <a href="https://wa.me/543517537208" className="btn btn-outline-success btnWapp">Whatsapp</a>
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-8">
              <h4 className="txtBuscar">• Seguinos en nuestras redes</h4>
            </div>
            <div className="col-4">
              <a className="link-body-emphasis iconRedes" href="https://www.facebook.com/profile.php?id=100084055397359" title="Facebook">
                <LogoFacebook color={"#00000"} height="40px" width="40px" />
              </a>

              <a className="link-body-emphasis iconRedes" href="https://www.instagram.com/polleriaerika/" title="Instagram">
                <LogoInstagram color={"#00000"} height="40px" width="40px" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <p className="text-center text-body-secondary">
        Diseñado por{" "}
        <a href="https://www.linkedin.com/in/nacho-caceres/" className="perse">
          Persevera
        </a>{" "}
        © {new Date().getFullYear()} Polleria Erika
      </p>
    </footer>
  );
}

export default Footer;
